import * as React from 'react';
import InnerHTML from 'dangerously-set-html-content';

function ContactForm({ isSocialMedia = false }): JSX.Element {
  const html = isSocialMedia
    ? '<div class="pipedriveWebForms" data-pd-webforms="https://webforms.pipedrive.com/f/30YWPsE2Dep4CibaKvfw8H6AMeSUwocARRti5enPOwnfUOgAWeUTkitBzASAqHjk7"><script src="https://webforms.pipedrive.com/f/loader"></script></div>'
    : '<div class="pipedriveWebForms" data-pd-webforms="https://webforms.pipedrive.com/f/1wDDaWUyEK2WgsxcoOK71U1vY1QBRXx62jry4QgvRfr0GOXCaxadtdl1QkSDIzmEz"><script src="https://webforms.pipedrive.com/f/loader"></script></div>';

  const testhtml = `<script type="text/javascript" src="https://buildertrend.net/leads/contactforms/js/btClientContactForm.js"></script> 
    <iframe src="https://buildertrend.net/leads/contactforms/ContactFormFrame.aspx?builderID=85661" scrolling="no" id="btIframe" style="background:transparent;border:0px;margin:0 auto;width:100%;"></iframe>`;
  return (
    <div className="text-light">
      <div className="max-w-[30rem] mx-auto">
        <h2 className="text-2xl font-bold text-black">Get in touch </h2>
        <InnerHTML html={testhtml} className="mt-6" />
      </div>
    </div>
  );
}

export { ContactForm };
